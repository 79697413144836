.view-filters {
  @extend .well;
  margin-top: 20px;
  border-radius: 6px;
}

.views-widget-filter-field_text_date_value,
.views-widget-filter-field_work_date_value {
  label {
    display: block;
  }
  .views-widget {
    display: inline-block;
    .form-item {
      display: inline-block
    }
  }
}