.views-bootstrap-grid-plugin-style {
  .col {
    article {
      background: #f5f5f5;
      border-radius: 6px;
      margin-bottom: 2rem;
      .content {
        padding: 2rem;
      }
    }
    img {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }
}