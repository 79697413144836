.breadcrumbs {
  margin-bottom: 1rem;
  .container-smooth {
    padding-left: 0;
  }
  ul {
    margin: 0;
    padding: 0;
    white-space: nowrap;

    li {
      list-style: none;
      display: inline;

      @include breakpoint(xs) {
        display: none;

        &.first,
        &.last {
          display: inline;
        }
      }
    }
  }

  .breadcrumb-seperator {
    margin: 0 1rem;
  }
  a {
    display: inline-block;
  }
}