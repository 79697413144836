@import url('https://fonts.googleapis.com/css?family=Raleway:700,700i|Source+Sans+Pro:400,400i,700,700i');

$header-font: 'Raleway', sans-serif;
$body-font: 'Source Sans Pro', sans-serif;

@mixin header-font() {
  font-family: $header-font;
  font-weight: 700;
  letter-spacing: 0.1rem;
  margin: 0 0 1rem 0;
  text-transform: uppercase;
}

@mixin link($color) {
  color: $color;
  border-bottom: 1px dotted $color;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    border-bottom: 1px dotted transparent;
    color: darken($color, 20%);
  }
}