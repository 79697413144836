.field-name-field-person-dates, .node--person-grid {
  .date-display-start, .date-display-single {
    &:before {
      content: 'b.';
      margin-right: 0.5rem;
    }
  }
  .date-display-end {
    &:before {
      content: 'd.';
      margin-right: 0.5rem;
    }
  }
}

.node--person-grid {
  .date-display-single {
    &:before {
      content: 'b.';
      margin-right: 0.5rem;
    }
  }
}