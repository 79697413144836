#footer {
  padding: 4rem 0;
  background: $brand-primary;
  color: white;
  .region-footer {
    margin-top: 4rem;

    @include breakpoint(sm) {
      display: flex;
      align-items: center;
      .block {
        flex: 50% 1 1;
        min-width: 1px; // Firefox overflow bug.
      }
    }
    .contact-information {
      margin-bottom: 2rem;
      .name {
        font-size: 2rem;
        font-weight: bold;
      }
      .titles {
        font-size: 1.8rem;
        font-style: italic;
      }
      address, .phone, .email {
        font-size: 1.4rem;
      }
    }
    .library-logo {
      a {
        border-bottom: 0;
      }
    }
  }
  a {
    @include link(white);
  }
}

.no-flexbox .region-footer .block {
  @include breakpoint(sm) {
    float: left;
    width: 50%;
  }
}