.menu-block-1 {
  .nav {
    li {
      margin-top: 0;
      a {
        border-radius: 0;
        border-bottom: 1px solid #e2e2e2;
        &:hover {
          color: white;
          background-color: $brand-primary;
        }
      }
      a.active {
        &:hover {
          background-color: $brand-primary;
        }
      }
    }
    li.expanded {
      ul {
        a {
          background-color: #e2e2e2;
          &:hover {
            background-color: $brand-primary;
          }
        }
      }
    }
  }
}