ul.links.inline {
  list-style-type: none;
  margin: 0;
  padding: 1rem 0;
  margin-top: 1.5rem;
  @extend .clearfix;

  a {
    &:before {
      @include fa('angle-right');
      border-radius: 50%;
      border: 2px solid $border-color;
      padding: 1rem 1.5rem;
      margin-right: 1rem;
    }
    border-bottom: 0;
    text-transform: uppercase;
    cursor: pointer;
    // @extend .btn;
    // @extend .btn-primary;
    // float: left;
    // margin-right: 1rem;
  }
}