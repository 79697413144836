.view-display-id-composers_grid_3,
.view-display-id-authors_poets_grid_3 {
  article {
    margin-bottom: 2rem;
  }
  .field-type-image {
    margin-bottom: 0;
  }
  .content {
    padding: 1rem;
    margin-bottom: 1rem;
    .node-title {
      margin-top: 0;
    }
  }
}