.view-display-id-promoted_list_all {
  margin-left: -2rem;
  margin-right: -2rem;
  .view-content {
    display: flex;
    flex-wrap: wrap;
    .views-row {
      flex: 530px 1 1;
      min-width: 1px; // Firefox overflow bug.
      margin: 2rem;
      article {
        padding: 4rem;
        border-radius: 6px;
        background-color: #353849;
      }
      article.node-sticky {
        background-color: $brand-primary;
      }
      a {
        @include link(white);
        border-bottom: 0;
      }
      h2 {
        padding-bottom: 1rem;
        font-size: 2.2rem;
        border-bottom: 2px solid $border-color;
      }
      .content {
        font-size: 2rem;
      }
    }
  }
}

.no-flexbox .view-display-id-promoted_list_all .view-content {
  .views-row {
    width: 530px;
    float: left;
  }
}