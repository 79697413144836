.block-superfish {
  @include header-font;
  .block__title {
    @extend .sr-only;
  }

  #superfish-1-toggle {
    padding: 1.7rem;
    display: block;
    width: 100%;
    text-align: right;
    color: white;

    &:before {
      @include fa(bars);
      font-size: 2.5rem;
    }

    &.sf-expanded {
      &:before {
        @include fa(times);
        font-size: 2.5rem;
      }
    }

    span {
      @extend .sr-only;
    }
  }

  // Shared styles
  .sf-style-none {
    li {
      a {
        padding: 1rem;
        color: white;
        text-decoration: none;
        cursor: pointer;
      }
    }

    // Top level menu items.
    a.sf-depth-1 {
      text-transform: uppercase;
    }
  }
  // Vertical styles.
  ul.sf-menu.sf-accordion.sf-expanded {
    position: absolute;
    padding: 2rem;
    background-color: #333;
  }

  // Horizontal styles.
  .sf-horizontal {
    text-align: center; 
    float: none;
    margin-top: 1.2rem;
    li {
      margin-left: 0.5rem;
      display: inline-block; 
      float: none;
      a {
        //border-radius: 5px;
        border: 0;
        //box-shadow: inset 0 0 0 2px $border-color;
        font-size: 1.4rem;
        padding-left: 4rem;
        padding-right: 4rem;
        height: 5rem;
        line-height: 5rem;
        padding-top: 0;
        &:hover, &:active {
          background-color: rgba(0,0,0,0.8);
        }
      }
      .active {
        background-color: rgba(0,0,0,0.8);
      }
    }
  }
}
