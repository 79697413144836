#header {
  border-bottom: 0;
  margin: 0;
  a {
    border-bottom: 0;
  }

  &.bg-image {
    @include transition(background-position .3s);
    @include box-shadow(0px -15px 15px -10px rgba(0,0,0,0.2) inset);
    background-size: 100% auto;
    background-position: 100% 0px;
    position: relative;
    min-height: 225px;
    &:before {
      content: "";
      @extend .pos-abs;
      @extend .pattern-overlay;
      @extend .dots;
      @extend .opacity-8;
    }
    @include breakpoint(sm) {
      min-height: 285px;

      .logged-in & {
        // Account for background-position if we are logged-in.
        background-position: 0px ($toolbar-offset);
      }
      .toolbar-drawer & {
        // Account for background-position if we are logged-in and the toolbar-drawer is exposed.
        background-position: 0px ($toolbar-drawer-offset);
      }
    }
    @include breakpoint(md) {
      min-height: 380px;
      // Move the image position when the viewport scales.
      background-position: 0px -10px;

      .logged-in & {
        // Account for background-position if we are logged-in.
        background-position: 0px (-10px + $toolbar-offset);
      }
      .toolbar-drawer & {
        // Account for background-position if we are logged-in and the toolbar-drawer is exposed.
        background-position: 0px (-10px + $toolbar-drawer-offset);
      }
    }
    @include breakpoint(lg) {
      // Move the image position when the viewport scales.
      background-position: 0px -80px;

      .logged-in & {
        // Account for background-position if we are logged-in.
        background-position: 0px (-80px + $toolbar-offset);
      }
      .toolbar-drawer & {
        // Account for background-position if we are logged-in and the toolbar-drawer is exposed.
        background-position: 0px (-80px + $toolbar-drawer-offset);
      }
    }
    .container-smooth {
      min-height: 225px;
      background-color: inherit;
      position: relative;
      @include breakpoint(sm) {
        background-color: transparent;
      }
    }
  }

  .header-navbar {
    @include breakpoint(sm) {
      background: rgba(0,0,0,0.5);
      border-radius: 2px;
    }
  }

  .branding {
    @include breakpoint(sm) {
      padding: 4rem 0 2rem 0;
      border-bottom: 2px solid $border-color;
      text-align: center;
    }
    @include breakpoint(md) {
      text-align: initial;
      padding-top: 10rem;
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
    .site-name {
      @include header-font;
      @extend h1;
      font-size: 5rem;
      color: white;
      @include breakpoint(sm) {
        text-shadow: 2px 2px 3px #222;
      }
    }
    #bg-image-info-link {
      visibility: hidden;
      margin-left: 1rem;
      border-radius: 4px;
      @include breakpoint(sm) {
        visibility: visible;
      }
    }
  }
}

#bg-image-modal {
  .bg-image-image {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -15px;
  }
}