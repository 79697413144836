html {
  font-size: $font-size-root;
  overflow-x: hidden;
}

body {
  font-family: $body-font;
  background-color: #f1f1f1;
}

a {
  @include link($brand-primary);
}
.contextual-links a {
  border-bottom: 0;
}

h1,
h2 {
  @include header-font;
}

.tabs {
  margin-bottom: 2rem;
}

.star {
  @include fa('star');
}

.more-link a{
  @extend .btn;
  @extend .btn-primary;
}
