.node--album-full {
  @include breakpoint(md) {
    .group-col-1 {
      display: flex;
      align-items: center;
    }

    .field-name-field-album-cover-image {
      flex: 300px 1 1;
      min-width: 1px; // Firefox overflow bug.
      margin-right: 2rem;
    }

    .group-details {
      display: table-cell; // IE.
      flex: 600px 1 1;
      min-width: 1px; // Firefox overflow bug.
    }
  }

  .group-col-2 {
    > .field {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
}