.front {
  #main-wrapper {
    background-color: #2e3141;
    color: white;
    @include angle(before, true);
    #page-title {
      @extend .sr-only;
    }
    .home-node {
      .pane-title {
        @extend .sr-only;
      }
      .pane-content {
        font-size: 2.2rem;
      }
    }
    .region-content {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0;
    }
  }
}