.node--person-full {
  @include breakpoint(md) {
    .group-col-1 {
      display: flex;
      align-items: center;
    }

    .field-name-field-person-photo {
      flex: 300px 1 1;
      min-width: 1px; // Firefox overflow bug.
      margin-right: 2rem;
    }

    .group-details {
      display: table-cell; // IE.
      flex: 600px 1 1;
      min-width: 1px; // Firefox overflow bug.

      .field-name-person-name h2 {
        margin-top: 0;
      }
    }
  }

  .group-col-2 {
    > .field {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
}

.field-name-field-person-dates {
  font-size: 1.8rem;
  font-weight: bold;
}

.field-name-field-person-city {
  font-style: italic;
}
