.modal {
  color: white;
  a {
    @include link(white);
  }
  .modal-content {
    border: 0;
    border-radius: 3px;
    box-shadow: none;
    .modal-header {
      border-width: 2px;
    }
    .close {
      color: white;
      text-shadow: none;
    }
  }
}

.modal-open #ui-wrapper, .modal-open #header,
.modal-open #main-wrapper, .modal-open #footer,
.modal-open #ui-global-footer {
  filter: blur(2px);
}