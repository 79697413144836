#main-wrapper {
  margin-top: -20px;
  padding: 1.5% 0; // Supports up to ~4000px wide me thinks.
  background-color: inherit;
  @include angle(both, true);
  .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    background-color: $brand-primary;
    color: white;
  }
  .main {
    padding: 4rem 0;
  }
  .panel-display {
    .container-fluid {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .region-content {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid #e2e2e2;
    @include breakpoint(md) {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0;
    }
  }
}