.field-label-inline > div {
  display: inline;
}
.field-item {
  display: inline;
}

.field-label {
  font-weight: bold;
}

.field {
  margin-bottom: 1rem;
}
